﻿var config = (function () {
    var authServerUrl = "https://auth.ischool.com.tw/";
	var greeningServerUrl = "https://greening.ischool.com.tw/dsa/greening/";
	
    authServerUrl = authServerUrl.replace(/\/$/g, '') + "/";
    greeningServerUrl = greeningServerUrl.replace(/\/$/g, '') + "/";
    return {
        authServer: function (path) {
            return authServerUrl + path.replace(/^\//g, '');
        },
        greeningServer: function (path) {
            return greeningServerUrl + path.replace(/^\//g, '');
        },
        getDsns: function () {
            return "";//"url" || ["url1","url2"]
        }
    };
})();