﻿/// <reference path="dsutil.js"/>
/// <reference path="parseXml.js"/>
/// <reference path="xmlWriter.js"/>
/// <reference path="config.js"/>
var authStatus = {
    "unknow": "unknow"
    , "authorized": "authorized"
    , "authorizing": "authorizing"
    , "unauthorized": "unauthorized"
    , "authorize_failed": "authorize_failed"
};
auth = (function () {

    var firstTimeLogin = true;
    var loginCallback = [];
    var greeningConnection = null;
    var sessionID = "";
    var errorCode = "";
    var errorMessage = "";
    var logoutCallBack = [];
    var detectedNetworkCostCallBack = [];
    var onloginCallBack = [];
    var currentSessionID = "";
    var currentUserAccount = "";
    var openingWindow = null;
    var currentUserInfo = {};

    var linkedAccount = {};

    var status = authStatus.unknow;
    var statusChangedCallBack = [];
    function statusChanged() {
        for (var i = 0; i < statusChangedCallBack.length; i++) {
            statusChangedCallBack[i](status, errorCode, errorMessage);
        }
    }

    var justAlived = false;
    function resetAlive() {
        justAlived = false;
    }
    function aliveConnection(callback) {
        if (!justAlived) {
            justAlived = true;
            setTimeout(resetAlive, 900000);
            try {
                if (greeningConnection && greeningConnection.send) {
                    greeningConnection.send({//讓greeningConnection的SessionID展期
                        service: 'DS.Base.Connect',
                        body: {},
                        result: function (resp, errorInfo, XMLHttpRequest) {
                            if (errorInfo == null) {
                                if (callback) callback();
                            }
                            else {
                                killedAllLinkedAccount();
                                if (callback) callback();
                            }
                        }
                    });
                }
                else {
                    killedAllLinkedAccount();
                    if (callback) callback();
                }
            }
            catch (ex) {
                killedAllLinkedAccount();
                if (callback) callback();
            }
        }
        else {
            if (callback) callback();
        }
    }
    function requestLogin(newStatus, callback) {
        if (callback) {
            loginCallback.push(callback);
        }
        if (status != newStatus) {
            status = newStatus;
            statusChanged();
        }
    }
    function login(callback) {
        if (callback) {
            loginCallback.push(callback);
        }
        if ((!!greeningConnection) && greeningConnection.send) {
            if (status == authStatus.authorizing) return;
            greeningConnection.reConnect(sessionID);
        }
        else {
            greeningConnection = dsutil.creatConnection(config.greeningServer("user"), sessionID);
            if (status != authStatus.authorizing) {
                status = authStatus.authorizing;
                statusChanged();
            }
            greeningConnection.OnLoginError(function () {
                requestLogin(authStatus.authorize_failed);
            });
        }
        greeningConnection.send({
            service: 'DS.Base.Connect',
            autoRetry: true,
            result: function (resp, errorInfo, XMLHttpRequest) {
                if (errorInfo == null) {
                    //#region 設定rootAlive
                    justAlived = true;
                    setTimeout(resetAlive, 900000);
                    //#endregion
                    //#region 整理UserInfo
                    currentUserInfo = {};
                    var userInfo = greeningConnection.getUserInfo();
                    for (var key in userInfo) {
                        if (key != "Property")
                            currentUserInfo[key] = userInfo[key];
                    }
                    if (userInfo && userInfo.Property) {
                        if ($.isArray(userInfo.Property)) {
                            for (var i = 0; i < userInfo.Property.length; i++) {
                                currentUserInfo[userInfo.Property[i].Name] = userInfo.Property[i]['@text'];
                            }
                        }
                        else {
                            currentUserInfo[userInfo.Property.Name] = userInfo.Property['@text'];
                        }
                    }
                    //#endregion
                    //#region 如果是重新登入但登入的使用者不同，幹掉原使用者的暫存狀態
                    if (currentUserAccount && (currentUserAccount != currentUserInfo.UserName)) {
                        if (currentSessionID) {
                            dsutil.creatConnection(config.greeningServer("user"), currentSessionID).send({ service: 'DS.Base.InvalidateSession' });
                        }

                        killedAllLinkedAccount();
                        loginCallback = [];
                        connectionPool = {};
                        for (var i = 0; i < logoutCallBack.length; i++) {
                            logoutCallBack[i]();
                        }
                    }
                    //#endregion
                    //#region 處理loginCallback
                    var cacheloginCallback = loginCallback;
                    loginCallback = [];
                    for (var i = cacheloginCallback.length - 1; i >= 0; i--) {
                        cacheloginCallback[i]();
                    };
                    //#endregion
                    //#region 設定登入者身份及狀態
                    currentSessionID = sessionID;
                    if (userInfo.UserName != currentUserAccount) {
                        currentUserAccount = userInfo.UserName;
                        for (var i = 0; i < onloginCallBack.length; i++) {
                            onloginCallBack[i]();
                        }
                    }
                    if (status != authStatus.authorized) {
                        status = authStatus.authorized;
                        statusChanged();
                    }
                    //#endregion
                }
                else {
                    requestLogin(authStatus.authorize_failed);
                }
            }
        });
    }
    function killedAllLinkedAccount() {
        for (var acc in linkedAccount) {
            if (!!linkedAccount[acc].kill)
                linkedAccount[acc].kill();
            linkedAccount[acc] = null;
        }
        linkedAccount = {};
    }

    function getLinkedAccount(account) {
        if (!!!linkedAccount[account]) {
            linkedAccount[account] = (function (acc) {
                var greeningPassport = null;
                var gettingPassport = [];
                var connectionPool = {};
                function getPassportThenCallBack() {
                    greeningConnection.send({
                        //                        service: 'DS.Base.GetPassportToken',
                        //                        body: {},
                        service: (acc == "" || acc == currentUserAccount ? 'DS.Base.GetPassportToken' : 'GetPassport'),
                        body: (acc == "" || acc == currentUserAccount ? {} : { Request: { User: acc } }),
                        autoRetry: true,
                        result: function (resp, errorInfo, XMLHttpRequest) {
                            if (resp) {
                                greeningPassport = {
                                    '@': ['type'],
                                    Type: 'Passport',
                                    DSAPassport: (acc == "" || acc == currentUserAccount ? resp.DSAPassport : resp.Response.DSAPassport)
                                };
                                var cachegettingPassport = gettingPassport;
                                gettingPassport = [];
                                for (var i = cachegettingPassport.length - 1; i >= 0; i--) {
                                    cachegettingPassport[i]();
                                };
                            }
                            else {
                                if (errorInfo && errorInfo.dsaError) {
                                    if (errorInfo.dsaError.status == '511') {
                                        requestLogin(status, getPassportThenCallBack);
                                    }
                                    else
                                        alert('unexcept error on GetPassportToken and errorInfo.dsaError.status=' + errorInfo.dsaError.status);
                                    //                            if (errorInfo.dsaError.status == '512') {
                                    //                                //應在此判斷當dsaError.status == '511'(SessionExpire)時進行重登後再重試
                                    //                                //alert('dsa error on GetPassportToken' + errorInfo.dsaError.status + ' ' + errorInfo.dsaError.message);
                                    //                                callLoginWithCallBack(getPassportThenCallBack);
                                    //                            } else {
                                    //                                alert('dsa error on GetPassportToken' + errorInfo.dsaError.status + ' ' + errorInfo.dsaError.message);
                                    //                            }
                                }
                                else {
                                    alert('unexcept error on GetPassportToken and no errorInfo!!');
                                }
                            }
                        }
                    });
                }
                function getPassport(callback) {
                    if (callback) {
                        gettingPassport.push(callback);
                    }
                    else {
                        gettingPassport.push(function () { });
                    }
                    if (greeningConnection && greeningConnection.send) {
                        if (gettingPassport.length == 1) {
                            getPassportThenCallBack();
                        }
                    }
                    else {
                        loginCallback.push(getPassportThenCallBack);
                    }
                }
                return {
                    getConnectionProxy: function (accesspoint, account, password) {
                        var reqlist = [];
                        var usePassport = !account;
                        var conn = {};
                        var loginFailedCallBack = [];
                        var readyCallBackQueue = [];
                        function sendAllReq() {
                            if (conn.send) {
                                var cachelist = reqlist;
                                reqlist = [];
                                for (var i = cachelist.length - 1; i >= 0; i--) {
                                    sendReq(cachelist[i]);
                                };
                            }
                        }
                        function sendReq(req) {
                            var proxyReq = {};
                            proxyReq.service = req.service || '';
                            proxyReq.body = req.body || {};
                            proxyReq.autoRetry = req.autoRetry || false;
                            proxyReq.result = function (resp, errorInfo, XMLHttpRequest, fullResponse) {// errorInfo=null||{'loginError': null,'dsaError': null,'networkError': null,'ajaxException': null}
                                if (resp || resp === "") {
                                    req.title = "Service：" + req.service;
                                    req.response = resp;
                                    req.errorInfo = errorInfo;
                                    req.accessPoint = conn.getAccessPoint();
                                    req.ueerInfo = conn.getUserInfo();

                                    try {
                                        if (req.startTime) {
                                            var costTime = new Date().getTime() - req.startTime;
                                            var serviceTarget = conn.getAccessPoint().split('/');
                                            analytics.push(['_trackTiming', "Service Call", "" + (serviceTarget.length > 2 ? (serviceTarget[serviceTarget.length - 1] + " ") : "") + req.service, costTime, (serviceTarget.length > 2 ? serviceTarget[serviceTarget.length - 2] : ""), 100]);
                                            var parse = fullResponse;
                                            var processTime;
                                            if (parse.Envelope && parse.Envelope.Header && parse.Envelope.Header.ProcessTime) {
                                                processTime = Number(parse.Envelope.Header.ProcessTime);
                                            }
                                            if (processTime) {
                                                analytics.push(['_trackTiming', "Server Process", "" + (serviceTarget.length > 2 ? (serviceTarget[serviceTarget.length - 1] + " ") : "") + req.service, processTime, (serviceTarget.length > 2 ? serviceTarget[serviceTarget.length - 2] : ""), 100]);
                                                $(detectedNetworkCostCallBack).each(function (index, fn) {
                                                    fn(costTime, processTime, costTime - processTime);
                                                });
                                            }
                                        }
                                    }
                                    catch (exception) { }
                                    if (req.result) {
                                        req.result(resp, errorInfo, XMLHttpRequest);
                                    }
                                }
                                else {
                                    if (errorInfo.loginError !== null && errorInfo.loginError.statusCode == '512') {//如果是正在登入且發生Passport過期(conn的onLoginError會自動進行更換passport再重登)
                                        getPassport(function () {
                                            conn.send(proxyReq);
                                        });
                                    }
                                    else
                                        if (errorInfo.dsaError !== null && errorInfo.dsaError.status == '511') {//連到這個accessPoint的sessionID過期
                                            conn.reConnect(greeningPassport); //直接用passport重連，如果passport也過期會進入onLoginError自動進行更換passport再重登
                                            conn.send(proxyReq);
                                        }
                                        else {
                                            req.title = "Service：" + req.service;
                                            req.response = resp;
                                            req.errorInfo = errorInfo;
                                            req.accessPoint = conn.getAccessPoint();
                                            req.ueerInfo = conn.getUserInfo();
                                            try {
                                                if (req.startTime) {
                                                    var costTime = new Date().getTime() - req.startTime;
                                                    var serviceTarget = conn.getAccessPoint().split('/');
                                                    analytics.push(['_trackTiming', "Service Call", "" + (serviceTarget.length > 2 ? (serviceTarget[serviceTarget.length - 1] + " ") : "") + req.service, costTime, "" + (serviceTarget.length > 2 ? serviceTarget[serviceTarget.length - 2] : ""), 100]);
                                                    var parse = fullResponse;
                                                    var processTime;
                                                    if (parse.Envelope && parse.Envelope.Header && parse.Envelope.Header.ProcessTime) {
                                                        processTime = Number(parse.Envelope.Header.ProcessTime);
                                                    }
                                                    if (processTime) {
                                                        analytics.push(['_trackTiming', "Server Process", "" + (serviceTarget.length > 2 ? (serviceTarget[serviceTarget.length - 1] + " ") : "") + req.service, processTime, " " + (serviceTarget.length > 2 ? serviceTarget[serviceTarget.length - 2] : ""), 100]);
                                                        $(detectedNetworkCostCallBack).each(function (index, fn) {
                                                            fn(costTime, processTime, costTime - processTime);
                                                        });
                                                    }
                                                }
                                            }
                                            catch (exception) { }
                                            if (req.result) {
                                                req.result(resp, errorInfo, XMLHttpRequest);
                                            }
                                        }
                                }
                            };
                            aliveConnection(function () {
                                conn.send(proxyReq);
                            });
                        }
                        var connectionProxy = {
                            send: function (req) {
                                req.startTime = new Date().getTime();
                                reqlist.push(req);
                                sendAllReq();
                            },
                            getUserInfo: function () {
                                return conn.getUserInfo ? conn.getUserInfo() : "";
                            },
                            getAccessPoint: function () {
                                return conn.getAccessPoint ? conn.getAccessPoint() : "";
                            },
                            ready: function (fn) {
                                if (conn.ready) {
                                    conn.ready(function () {
                                        fn();
                                    });
                                }
                                else {
                                    readyCallBackQueue.push(fn);
                                }
                            },
                            loginFailed: function (fn) {
                                if (fn && $.isFunction(fn)) {
                                    loginFailedCallBack.push(fn);
                                }
                            }
                        };
                        function onLoginError(loginError) {
                            if (loginError.statusCode == '512' || loginError.statusCode == '511') {
                                getPassport(function () {
                                    conn.reConnect(greeningPassport);
                                });
                            }
                            else {
                                $(loginFailedCallBack).each(function (index, fn) {
                                    try {
                                        fn(loginError);
                                    }
                                    catch (ex) { }
                                });
                            }
                            conn.hasLoginError = true;
                        };
                        if (!usePassport) {
                            if (password)
                                conn = dsutil.creatConnection(accesspoint, account, password);
                            else
                                conn = dsutil.creatConnection(accesspoint, account);
                        }
                        else {
                            if (connectionPool[accesspoint] === undefined) {
                                //conn=XXXX
                                if (greeningPassport == null) {
                                    getPassport(function () {//只有在最開始連passport都是null的情況下會出現conn={}的空窗期(直到getPassport的callBack)
                                        if (connectionPool[accesspoint] === undefined) {
                                            conn = dsutil.creatConnection(accesspoint, greeningPassport);
                                            conn.OnLoginError(onLoginError);
                                            connectionPool[accesspoint] = conn;
                                        }
                                        else {
                                            conn = connectionPool[accesspoint];
                                        }
                                        $(readyCallBackQueue).each(function () {
                                            var fn = this;
                                            conn.ready(function () {
                                                fn();
                                            });
                                        });
                                        readyCallBackQueue = [];
                                        sendAllReq();
                                    });
                                }
                                else {
                                    conn = dsutil.creatConnection(accesspoint, greeningPassport);
                                    conn.OnLoginError(onLoginError);
                                    connectionPool[accesspoint] = conn;
                                }
                            }
                            else {
                                conn = connectionPool[accesspoint];
                                if (conn.hasLoginError === true) {
                                    conn.hasLoginError = false;
                                    conn.reConnect(greeningPassport);
                                }
                            }
                        }
                        return connectionProxy;
                    },
                    kill: function () {
                        gettingPassport = [];
                        greeningPassport = null;
                        for (var i in connectionPool) {
                            connectionPool[i].send({ service: 'DS.Base.InvalidateSession' });
                        }
                    }
                };
            })(account);
        }
        return linkedAccount[account];
    }
    var authFrom;
    var result = {
        connectTo: function (accesspoint, account, password) { return getLinkedAccount(currentUserAccount).getConnectionProxy(accesspoint, account, password); },
        linkAccountConnect: function (account, accesspoint) { return getLinkedAccount(account).getConnectionProxy(accesspoint); },
        setLoginStatus: function (sid, errCode, errMessage, authfrom) {
            sessionID = sid || "";
            errorCode = errCode;
            errorMessage = errMessage;
            if (sid) {
                //if (status != authStatus.authorizing) {
                //    status = authStatus.authorizing;
                //    statusChanged();
                //}
                login();
            }
            else {
                if (status != authStatus.unauthorized) {
                    status = authStatus.unauthorized;
                    statusChanged();
                }
            }
            authFrom = authfrom;
        },
        logout: function () {
            killedAllLinkedAccount();
            greeningConnection.send({
                service: 'DS.Base.InvalidateSession',
                result: function () {
                    loginCallback = [];
                    currentSessionID = "";
                    currentUserAccount = "";
                    if (status != authStatus.unauthorized) {
                        status = authStatus.unauthorized;
                        statusChanged();
                    }
                    for (var i = 0; i < logoutCallBack.length; i++) {
                        logoutCallBack[i]();
                    }
                }
            });
        },
        checkCurrentUser: function (account) {
            return (currentUserAccount && (currentUserAccount != account));
        },
        onLogout: function (fn) {
            if (fn) {
                logoutCallBack.push(fn);
            }
        },
        onLogin: function (fn) {
            if (fn) {
                onloginCallBack.push(fn);
            }
        },
        getStatus: function () {
            return status;
        },
        onStatusChanged: function (fn) {
            if (fn) {
                statusChangedCallBack.push(fn);
            }
        },
        getCurrentUserAccount: function () {
            return currentUserAccount;
        },
        onDetectedNetworkCost: function (fn) {
            if (fn && $.isFunction(fn)) {
                detectedNetworkCostCallBack.push(fn);
            }
        },
        getAuthFrom: function () {
            return authFrom;
        }
    };

    //接收post message來的登入處理
    window.addEventListener("message", function (e) {
        if (config.authServer("/").indexOf(e.origin) == 0) {
            var data = JSON.parse(e.data);
            result.setLoginStatus(data.sid, data.errCode, data.errMessage, data.authfrom);
        }
    }, false);

    return result;
}());
